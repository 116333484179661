export default [
  {
    varCodigo: '1',
    varDescripcion: 'Peruana',
    varAux1: 'PE',
  },
  {
    varCodigo: '2',
    varDescripcion: 'Afganistán',
    varAux1: 'AF',
  },
  {
    varCodigo: '3',
    varDescripcion: 'Albania',
    varAux1: 'AL',
  },
  {
    varCodigo: '4',
    varDescripcion: 'Alemania',
    varAux1: 'DE',
  },
  {
    varCodigo: '5',
    varDescripcion: 'Andorra',
    varAux1: 'AD',
  },
  {
    varCodigo: '6',
    varDescripcion: 'Angola',
    varAux1: 'AO',
  },
  {
    varCodigo: '7',
    varDescripcion: 'Anguilla',
    varAux1: 'AL',
  },
  {
    varCodigo: '8',
    varDescripcion: 'Antigua y Barbuda',
    varAux1: 'AG',
  },
  {
    varCodigo: '9',
    varDescripcion: 'Arabia Saudí ',
    varAux1: 'SA',
  },
  {
    varCodigo: '10',
    varDescripcion: 'Argelia',
    varAux1: 'DZ',
  },
  {
    varCodigo: '11',
    varDescripcion: 'Argentina',
    varAux1: 'AR',
  },
  {
    varCodigo: '12',
    varDescripcion: 'Armenia',
    varAux1: 'AM',
  },
  {
    varCodigo: '13',
    varDescripcion: 'Australia',
    varAux1: 'AU',
  },
  {
    varCodigo: '14',
    varDescripcion: 'Austria',
    varAux1: 'AT',
  },
  {
    varCodigo: '15',
    varDescripcion: 'Bahamas',
    varAux1: 'BS',
  },
  {
    varCodigo: '16',
    varDescripcion: 'Bangladesh',
    varAux1: 'BD',
  },
  {
    varCodigo: '17',
    varDescripcion: 'Barbados',
    varAux1: 'BB',
  },
  {
    varCodigo: '18',
    varDescripcion: 'Bélgica',
    varAux1: 'BE',
  },
  {
    varCodigo: '19',
    varDescripcion: 'Belice',
    varAux1: 'BZ',
  },
  {
    varCodigo: '20',
    varDescripcion: 'Bermudas',
    varAux1: 'BM',
  },
  {
    varCodigo: '21',
    varDescripcion: 'Bolivia',
    varAux1: 'BO',
  },
  {
    varCodigo: '22',
    varDescripcion: 'Bosnia y Herzegovina',
    varAux1: 'BA',
  },
  {
    varCodigo: '23',
    varDescripcion: 'Botsuana',
    varAux1: 'BW',
  },
  {
    varCodigo: '24',
    varDescripcion: 'Brasil',
    varAux1: 'BR',
  },
  {
    varCodigo: '25',
    varDescripcion: 'Bulgaria',
    varAux1: 'BG',
  },
  {
    varCodigo: '26',
    varDescripcion: 'Burkina Faso',
    varAux1: 'BF',
  },
  {
    varCodigo: '27',
    varDescripcion: 'Bután',
    varAux1: 'BT',
  },
  {
    varCodigo: '28',
    varDescripcion: 'Camboya',
    varAux1: 'KH',
  },
  {
    varCodigo: '29',
    varDescripcion: 'Camerún',
    varAux1: 'CM',
  },
  {
    varCodigo: '30',
    varDescripcion: 'Canadá',
    varAux1: 'CA',
  },
  {
    varCodigo: '31',
    varDescripcion: 'Chile',
    varAux1: 'CL',
  },
  {
    varCodigo: '32',
    varDescripcion: 'China',
    varAux1: 'CN',
  },
  {
    varCodigo: '33',
    varDescripcion: 'Chipre',
    varAux1: 'CY',
  },
  {
    varCodigo: '34',
    varDescripcion: 'Colombia',
    varAux1: 'CO',
  },
  {
    varCodigo: '35',
    varDescripcion: 'Congo',
    varAux1: 'CG',
  },
  {
    varCodigo: '36',
    varDescripcion: 'Corea (Sur)',
    varAux1: 'KR',
  },
  {
    varCodigo: '37',
    varDescripcion: 'Corea del Norte',
    varAux1: 'KP',
  },
  {
    varCodigo: '38',
    varDescripcion: 'Costa de Marfil',
    varAux1: 'CI',
  },
  {
    varCodigo: '39',
    varDescripcion: 'Costa Rica',
    varAux1: 'CR',
  },
  {
    varCodigo: '40',
    varDescripcion: 'Croacia',
    varAux1: 'HR',
  },
  {
    varCodigo: '41',
    varDescripcion: 'Cuba',
    varAux1: 'CU',
  },
  {
    varCodigo: '42',
    varDescripcion: 'Dinamarca',
    varAux1: 'DK',
  },
  {
    varCodigo: '43',
    varDescripcion: 'Ecuador',
    varAux1: 'EC',
  },
  {
    varCodigo: '44',
    varDescripcion: 'Egipto',
    varAux1: 'EG',
  },
  {
    varCodigo: '45',
    varDescripcion: 'El Salvador',
    varAux1: 'SV',
  },
  {
    varCodigo: '46',
    varDescripcion: 'El Vaticano',
    varAux1: 'VA',
  },
  {
    varCodigo: '47',
    varDescripcion: 'Emiratos Árabes Unidos',
    varAux1: 'AE',
  },
  {
    varCodigo: '48',
    varDescripcion: 'Escocia',
    varAux1: 'GB',
  },
  {
    varCodigo: '49',
    varDescripcion: 'Eslovaquia',
    varAux1: 'SK',
  },
  {
    varCodigo: '50',
    varDescripcion: 'Eslovenia',
    varAux1: 'SI',
  },
  {
    varCodigo: '51',
    varDescripcion: 'España',
    varAux1: 'ES',
  },
  {
    varCodigo: '52',
    varDescripcion: 'Estados Unidos',
    varAux1: 'US',
  },
  {
    varCodigo: '53',
    varDescripcion: 'Estonia',
    varAux1: 'EE',
  },
  {
    varCodigo: '54',
    varDescripcion: 'Etiopía',
    varAux1: 'ET',
  },
  {
    varCodigo: '55',
    varDescripcion: 'Fiji',
    varAux1: 'FJ',
  },
  {
    varCodigo: '56',
    varDescripcion: 'Filipinas',
    varAux1: 'PH',
  },
  {
    varCodigo: '57',
    varDescripcion: 'Finlandia',
    varAux1: 'FI',
  },
  {
    varCodigo: '58',
    varDescripcion: 'Francia',
    varAux1: 'FR',
  },
  {
    varCodigo: '59',
    varDescripcion: 'Ghana',
    varAux1: 'GH',
  },
  {
    varCodigo: '60',
    varDescripcion: 'Gibraltar',
    varAux1: 'GI',
  },
  {
    varCodigo: '61',
    varDescripcion: 'Grecia',
    varAux1: 'GR',
  },
  {
    varCodigo: '62',
    varDescripcion: 'Groenlandia',
    varAux1: 'GL',
  },
  {
    varCodigo: '63',
    varDescripcion: 'Guatemala',
    varAux1: 'GT',
  },
  {
    varCodigo: '64',
    varDescripcion: 'Guernsey',
    varAux1: 'GG',
  },
  {
    varCodigo: '65',
    varDescripcion: 'Guinea',
    varAux1: 'GN',
  },
  {
    varCodigo: '66',
    varDescripcion: 'Guyana',
    varAux1: 'GY',
  },
  {
    varCodigo: '67',
    varDescripcion: 'Haití',
    varAux1: 'HT',
  },
  {
    varCodigo: '68',
    varDescripcion: 'Holanda (Países Bajos)',
    varAux1: 'NL',
  },
  {
    varCodigo: '69',
    varDescripcion: 'Honduras',
    varAux1: 'HN',
  },
  {
    varCodigo: '70',
    varDescripcion: 'Hong Kong',
    varAux1: 'HK',
  },
  {
    varCodigo: '71',
    varDescripcion: 'Hungría',
    varAux1: 'HU',
  },
  {
    varCodigo: '72',
    varDescripcion: 'India',
    varAux1: 'IN',
  },
  {
    varCodigo: '73',
    varDescripcion: 'Indonesia',
    varAux1: 'ID',
  },
  {
    varCodigo: '74',
    varDescripcion: 'Irán',
    varAux1: 'IR',
  },
  {
    varCodigo: '75',
    varDescripcion: 'Iraq',
    varAux1: 'IQ',
  },
  {
    varCodigo: '76',
    varDescripcion: 'Irlanda',
    varAux1: 'IE',
  },
  {
    varCodigo: '77',
    varDescripcion: 'Islandia',
    varAux1: 'IS',
  },
  {
    varCodigo: '78',
    varDescripcion: 'Islas Cayman',
    varAux1: 'KY',
  },
  {
    varCodigo: '79',
    varDescripcion: 'Islas Malvinas',
    varAux1: 'FK',
  },
  {
    varCodigo: '80',
    varDescripcion: 'Islas Vírgenes (Reino Unido)',
    varAux1: 'VG',
  },
  {
    varCodigo: '81',
    varDescripcion: 'Islas Vírgenes Americanas',
    varAux1: 'VI',
  },
  {
    varCodigo: '82',
    varDescripcion: 'Israel',
    varAux1: 'IL',
  },
  {
    varCodigo: '83',
    varDescripcion: 'Italia',
    varAux1: 'IT',
  },
  {
    varCodigo: '84',
    varDescripcion: 'Jamaica',
    varAux1: 'JM',
  },
  {
    varCodigo: '85',
    varDescripcion: 'Japón',
    varAux1: 'JP',
  },
  {
    varCodigo: '86',
    varDescripcion: 'Jersey',
    varAux1: 'JE',
  },
  {
    varCodigo: '87',
    varDescripcion: 'Jordania',
    varAux1: 'JO',
  },
  {
    varCodigo: '88',
    varDescripcion: 'Kazajistán',
    varAux1: 'KZ',
  },
  {
    varCodigo: '89',
    varDescripcion: 'Kenia',
    varAux1: 'KE',
  },
  {
    varCodigo: '90',
    varDescripcion: 'Kuwait',
    varAux1: 'KW',
  },
  {
    varCodigo: '91',
    varDescripcion: 'Lao PDR',
    varAux1: 'LA',
  },
  {
    varCodigo: '92',
    varDescripcion: 'Líbano',
    varAux1: 'LB',
  },
  {
    varCodigo: '93',
    varDescripcion: 'Libia',
    varAux1: 'LY',
  },
  {
    varCodigo: '94',
    varDescripcion: 'Lituania',
    varAux1: 'LT',
  },
  {
    varCodigo: '95',
    varDescripcion: 'Luxemburgo',
    varAux1: 'LU',
  },
  {
    varCodigo: '96',
    varDescripcion: 'Madagascar',
    varAux1: 'MG',
  },
  {
    varCodigo: '97',
    varDescripcion: 'Malasia',
    varAux1: 'MY',
  },
  {
    varCodigo: '98',
    varDescripcion: 'Isla de Man',
    varAux1: 'IM',
  },
  {
    varCodigo: '99',
    varDescripcion: 'Marruecos',
    varAux1: 'MA',
  },
  {
    varCodigo: '100',
    varDescripcion: 'México',
    varAux1: 'MX',
  },
  {
    varCodigo: '101',
    varDescripcion: 'Mongolia',
    varAux1: 'MN',
  },
  {
    varCodigo: '102',
    varDescripcion: 'Mónaco',
    varAux1: 'MC',
  },
  {
    varCodigo: '103',
    varDescripcion: 'Mozambique',
    varAux1: 'MZ',
  },
  {
    varCodigo: '104',
    varDescripcion: 'Myanmar',
    varAux1: 'MM',
  },
  {
    varCodigo: '105',
    varDescripcion: 'Namibia',
    varAux1: 'NA',
  },
  {
    varCodigo: '106',
    varDescripcion: 'Nepal',
    varAux1: 'NP',
  },
  {
    varCodigo: '107',
    varDescripcion: 'Nicaragua',
    varAux1: 'NI',
  },
  {
    varCodigo: '108',
    varDescripcion: 'Níger',
    varAux1: 'NE',
  },
  {
    varCodigo: '109',
    varDescripcion: 'Nigeria',
    varAux1: 'NG',
  },
  {
    varCodigo: '110',
    varDescripcion: 'Isla de Navidad',
    varAux1: 'CX',
  },
  {
    varCodigo: '111',
    varDescripcion: 'Noruega',
    varAux1: 'NO',
  },
  {
    varCodigo: '112',
    varDescripcion: 'Nueva Zelanda',
    varAux1: 'NZ',
  },
  {
    varCodigo: '113',
    varDescripcion: 'Pakistán',
    varAux1: 'PK',
  },
  {
    varCodigo: '114',
    varDescripcion: 'Panamá',
    varAux1: 'PA',
  },
  {
    varCodigo: '115',
    varDescripcion: 'Papúa Nueva Guinea',
    varAux1: 'PG',
  },
  {
    varCodigo: '116',
    varDescripcion: 'Paraguay',
    varAux1: 'PY',
  },
  {
    varCodigo: '117',
    varDescripcion: 'Polonia',
    varAux1: 'PL',
  },
  {
    varCodigo: '118',
    varDescripcion: 'Portugal',
    varAux1: 'PT',
  },
  {
    varCodigo: '119',
    varDescripcion: 'Puerto Rico',
    varAux1: 'PR',
  },
  {
    varCodigo: '120',
    varDescripcion: 'Reino Unido',
    varAux1: 'GB',
  },
  {
    varCodigo: '121',
    varDescripcion: 'República Checa',
    varAux1: 'CZ',
  },
  {
    varCodigo: '122',
    varDescripcion: 'República Dominicana',
    varAux1: 'DO',
  },
  {
    varCodigo: '123',
    varDescripcion: 'Ruanda',
    varAux1: 'RW',
  },
  {
    varCodigo: '124',
    varDescripcion: 'Rumania',
    varAux1: 'RO',
  },
  {
    varCodigo: '125',
    varDescripcion: 'Rusia',
    varAux1: 'RU',
  },
  {
    varCodigo: '126',
    varDescripcion: 'Samoa',
    varAux1: 'WS',
  },
  {
    varCodigo: '127',
    varDescripcion: 'Seychelles',
    varAux1: 'SC',
  },
  {
    varCodigo: '128',
    varDescripcion: 'Senegal',
    varAux1: 'SN',
  },
  {
    varCodigo: '129',
    varDescripcion: 'Serbia',
    varAux1: 'RS',
  },
  {
    varCodigo: '130',
    varDescripcion: 'Singapur',
    varAux1: 'SG',
  },
  {
    varCodigo: '131',
    varDescripcion: 'Siria',
    varAux1: 'SY',
  },
  {
    varCodigo: '132',
    varDescripcion: 'Somalia',
    varAux1: 'SO',
  },
  {
    varCodigo: '133',
    varDescripcion: 'Sudáfrica',
    varAux1: 'ZA',
  },
  {
    varCodigo: '134',
    varDescripcion: 'Suecia',
    varAux1: 'SE',
  },
  {
    varCodigo: '135',
    varDescripcion: 'Suiza',
    varAux1: 'CH',
  },
  {
    varCodigo: '136',
    varDescripcion: 'Tailandia',
    varAux1: 'TH',
  },
  {
    varCodigo: '137',
    varDescripcion: 'Taiwan',
    varAux1: 'TW',
  },
  {
    varCodigo: '138',
    varDescripcion: 'Túnez',
    varAux1: 'TN',
  },
  {
    varCodigo: '139',
    varDescripcion: 'Turquía',
    varAux1: 'TR',
  },
  {
    varCodigo: '140',
    varDescripcion: 'Ucrania',
    varAux1: 'UA',
  },
  {
    varCodigo: '141',
    varDescripcion: 'Uganda',
    varAux1: 'UG',
  },
  {
    varCodigo: '142',
    varDescripcion: 'Uruguay',
    varAux1: 'UY',
  },
  {
    varCodigo: '143',
    varDescripcion: 'Vanuatu',
    varAux1: 'VU',
  },
  {
    varCodigo: '144',
    varDescripcion: 'Venezuela',
    varAux1: 'VE',
  },
  {
    varCodigo: '145',
    varDescripcion: 'Vietnam',
    varAux1: 'VN',
  },
  {
    varCodigo: '146',
    varDescripcion: 'Yemén',
    varAux1: 'YE',
  },
  {
    varCodigo: '147',
    varDescripcion: 'Zambia',
    varAux1: 'ZM',
  },
  {
    varCodigo: '148',
    varDescripcion: 'Zimbabue',
    varAux1: 'ZW',
  },
]
