export default [
  {
    compuestoID: '00010',
    cargo: 'Accionista',
  },
  {
    compuestoID: '00011',
    cargo: 'Director Gerente',
  },
  {
    compuestoID: '00206',
    cargo: 'Gerente General',
  },
  {
    compuestoID: '00207',
    cargo: 'Gerente de Adm y Finanzas',
  },
  {
    compuestoID: '00208',
    cargo: 'Jefe de Adm y Finanzas',
  },
  {
    compuestoID: '00212',
    cargo: 'Otro',
  },
  {
    compuestoID: '00423',
    cargo: 'Apoderado',
  },
  {
    compuestoID: '00424',
    cargo: 'Presidente el Directorio',
  },
  {
    compuestoID: '00425',
    cargo: 'Director',
  },
  {
    compuestoID: '00426',
    cargo: 'Subgerente',
  },
  {
    compuestoID: '00427',
    cargo: 'Gerente Comercial',
  },
  {
    compuestoID: '00428',
    cargo: 'Gerente de Operaciones',
  },
  {
    compuestoID: '00430',
    cargo: 'Titular Gerente',
  },
  {
    compuestoID: '00432',
    cargo: 'Gerente Tecnico',
  },
  {
    compuestoID: '00439',
    cargo: 'Aval',
  },
  {
    compuestoID: '00444',
    cargo: 'Gerente General Adjunto',
  },
  {
    compuestoID: '00445',
    cargo: 'Gerente de Recursos Humanos',
  },
  {
    compuestoID: '00448',
    cargo: 'Jefe de Contabilidad',
  },
  {
    compuestoID: '01206',
    cargo: 'Jefe de Tesoreria',
  },
  {
    compuestoID: '01486',
    cargo: 'Gerente',
  },
  {
    compuestoID: '01487',
    cargo: 'Gerente Administrativo',
  },
  {
    compuestoID: '01490',
    cargo: 'Gerente de Finanzas',
  },
  {
    compuestoID: '01491',
    cargo: 'Gerente de Administración',
  },
]
