export default [
  {
    compuestoID: '00001',
    profesion: 'ABOGADO',
  },
  {
    compuestoID: '00002',
    profesion: 'ACTOR, ARTISTA Y DIRECTOR DE ESPECTACULOS',
  },
  {
    compuestoID: '00003',
    profesion: 'ADMINISTRADOR DE EMPRESAS (PROFESIONAL)',
  },
  {
    compuestoID: '00004',
    profesion: 'AGRIMENSOR Y TOPOGRAFO',
  },
  {
    compuestoID: '00005',
    profesion: 'AGRÓNOMO',
  },
  {
    compuestoID: '00006',
    profesion: 'ALBANIL',
  },
  {
    compuestoID: '00007',
    profesion: 'ANALISTAS DE SISTEMA Y COMPUTACION',
  },
  {
    compuestoID: '00008',
    profesion: 'ANTROPOLOGO, ARQUEOLOGO Y ETNOLOGO',
  },
  {
    compuestoID: '00009',
    profesion: 'ARQUITECTO',
  },
  {
    compuestoID: '00010',
    profesion: 'ARTESANO DE CUERO',
  },
  {
    compuestoID: '00011',
    profesion: 'ARTESANO TEXTIL',
  },
  {
    compuestoID: '00012',
    profesion: 'AUTOR LITERARIO, ESCRITOR Y CRITICO',
  },
  {
    compuestoID: '00013',
    profesion: 'BACTERIOLOGO, FARMACOLOGO',
  },
  {
    compuestoID: '00014',
    profesion: 'BIOLOGO',
  },
  {
    compuestoID: '00015',
    profesion: 'CARPINTERO',
  },
  {
    compuestoID: '00016',
    profesion: 'CONDUCTOR DE VEHICULOS DE MOTOR',
  },
  {
    compuestoID: '00017',
    profesion: 'CONTADOR',
  },
  {
    compuestoID: '00018',
    profesion: 'COREOGRAFO Y BAILARINES',
  },
  {
    compuestoID: '00019',
    profesion: 'COSMETOLOGO, PELUQUERO Y BARBERO',
  },
  {
    compuestoID: '00020',
    profesion: 'DECORADOR, DIBUJANTE, PUBLICISTA, DISEÑADOR DE PUBLICIDAD',
  },
  {
    compuestoID: '00021',
    profesion: 'DEPORTISTA PROFESIONAL Y ATLETA',
  },
  {
    compuestoID: '00022',
    profesion: 'DIRECTOR DE EMPRESAS',
  },
  {
    compuestoID: '00023',
    profesion: 'ECONOMISTA',
  },
  {
    compuestoID: '00024',
    profesion: 'ELECTRICISTA (TECNICO)',
  },
  {
    compuestoID: '00025',
    profesion: 'ENFERMERO',
  },
  {
    compuestoID: '00026',
    profesion: 'ENTRENADOR DEPORTIVO',
  },
  {
    compuestoID: '00027',
    profesion: 'ESCENOGRAFO',
  },
  {
    compuestoID: '00028',
    profesion: 'ESCULTOR',
  },
  {
    compuestoID: '00029',
    profesion: 'ESPECIALISTA EN TRATAMIENTO DE BELLEZA',
  },
  {
    compuestoID: '00030',
    profesion: 'FARMACEUTICO',
  },
  {
    compuestoID: '00031',
    profesion: 'FOTOGRAFO Y OPERADORES CAMARA, CINE Y TV',
  },
  {
    compuestoID: '00032',
    profesion: 'GASFITERO',
  },
  {
    compuestoID: '00033',
    profesion: 'GEOGRAFO',
  },
  {
    compuestoID: '00034',
    profesion: 'INGENIERO',
  },
  {
    compuestoID: '00035',
    profesion: 'INTERPRETE, TRADUCTOR, FILOSOFO',
  },
  {
    compuestoID: '00036',
    profesion: 'JOYERO Y/O PLATERO',
  },
  {
    compuestoID: '00037',
    profesion: 'LABORATORISTA (TECNICO)',
  },
  {
    compuestoID: '00038',
    profesion: 'LOCUTOR DE RADIO, TV',
  },
  {
    compuestoID: '00039',
    profesion: 'MECANICO MOTORES AVIONES Y NAVES MARINAS',
  },
  {
    compuestoID: '00040',
    profesion: 'MECANICO DE VEHICULOS DE MOTOR',
  },
  {
    compuestoID: '00041',
    profesion: 'MEDICO Y CIRUJANO',
  },
  {
    compuestoID: '00042',
    profesion: 'MODELO',
  },
  {
    compuestoID: '00043',
    profesion: 'MUSICO',
  },
  {
    compuestoID: '00044',
    profesion: 'NUTRICIONISTA',
  },
  {
    compuestoID: '00045',
    profesion: 'OBSTETRIZ',
  },
  {
    compuestoID: '00046',
    profesion: 'ODONTOLOGO',
  },
  {
    compuestoID: '00047',
    profesion: 'PERIODISTA',
  },
  {
    compuestoID: '00048',
    profesion: 'PILOTO DE AERONAVES',
  },
  {
    compuestoID: '00049',
    profesion: 'PINTOR',
  },
  {
    compuestoID: '00050',
    profesion: 'PROFESOR',
  },
  {
    compuestoID: '00051',
    profesion: 'PSICOLOGO',
  },
  {
    compuestoID: '00052',
    profesion: 'RADIO TECNICO',
  },
  {
    compuestoID: '00053',
    profesion: 'REGIDORES DE MUNICIPALIDADES',
  },
  {
    compuestoID: '00054',
    profesion: 'RELACIONISTA PUBLICO E INDUSTRIAL',
  },
  {
    compuestoID: '00055',
    profesion: 'SASTRE',
  },
  {
    compuestoID: '00056',
    profesion: 'SOCIOLOGO',
  },
  {
    compuestoID: '00057',
    profesion: 'TAPICERO',
  },
  {
    compuestoID: '00058',
    profesion: 'TAXIDERMISTA, DISECADOR DE ANIMALES',
  },
  {
    compuestoID: '00059',
    profesion: 'VETERINARIO',
  },
  {
    compuestoID: '00060',
    profesion: 'PODOLOGOS',
  },
  {
    compuestoID: '00061',
    profesion: 'ARCHIVERO',
  },
  {
    compuestoID: '00062',
    profesion: 'ALBACEA',
  },
  {
    compuestoID: '00063',
    profesion: 'GESTOR DE NEGOCIO',
  },
  {
    compuestoID: '00064',
    profesion: 'MANDATARIO',
  },
  {
    compuestoID: '00065',
    profesion: 'SINDICO',
  },
  {
    compuestoID: '00066',
    profesion: 'TECNOLOGOS MEDICOS',
  },
  {
    compuestoID: '00099',
    profesion: 'PROFESION U OCUPACION NO ESPECIFICADA',
  },
]
