export default [
  {
    varCodigo: '1',
    cargoPublico: 'Presidendencia de la República',
  },
  {
    varCodigo: '2',
    cargoPublico: 'Jefe del Gabinete de Consejeros',
  },
  {
    varCodigo: '3',
    cargoPublico: 'Consejeros y Asesores',
  },
  {
    varCodigo: '4',
    cargoPublico: 'Secretaría General',
  },
  {
    varCodigo: '5',
    cargoPublico: 'Director General de Oficina de Asesoría Jurídica',
  },
  {
    varCodigo: '6',
    cargoPublico: 'Director General de Administración y Operaciones',
  },
  {
    varCodigo: '7',
    cargoPublico: 'Jefe de la Casa Militar',
  },
  {
    varCodigo: '8',
    cargoPublico: 'Vice Presidente de la República',
  },
  {
    varCodigo: '9',
    cargoPublico: 'Asesores',
  },
  {
    varCodigo: '10',
    cargoPublico: 'Presidente del Consejo de Ministros',
  },
  {
    varCodigo: '11',
    cargoPublico: 'Gabinete de Asesores',
  },
  {
    varCodigo: '12',
    cargoPublico: 'Jefe del Comando Conjunto de las Fuerzas Armandas',
  },
  {
    varCodigo: '13',
    cargoPublico: 'Director General de Gestión Administrativa',
  },
  {
    varCodigo: '14',
    cargoPublico: 'Ministro',
  },
  {
    varCodigo: '15',
    cargoPublico: 'Vice Ministro',
  },
  {
    varCodigo: '16',
    cargoPublico: 'Comandante General del Ejercito del Perú',
  },
  {
    varCodigo: '17',
    cargoPublico: 'Jefe del Estado Mayor General del Ejército',
  },
  {
    varCodigo: '18',
    cargoPublico: 'Inspector General del Ejército',
  },
  {
    varCodigo: '19',
    cargoPublico: 'General del Ejército',
  },
  {
    varCodigo: '20',
    cargoPublico: 'General de División',
  },
  {
    varCodigo: '21',
    cargoPublico: 'General de Brigada',
  },
  {
    varCodigo: '22',
    cargoPublico: 'Coroneles del Ejército del Perú',
  },
  {
    varCodigo: '23',
    cargoPublico: 'Teniente Coroneles del Ejército del Perú',
  },
  {
    varCodigo: '24',
    cargoPublico: 'Comandante General de la Marina de Guerra del Perú',
  },
  {
    varCodigo: '25',
    cargoPublico: 'Jefe del Estado Mayor General de la Marina',
  },
  {
    varCodigo: '26',
    cargoPublico: 'Inspector General de la Marina',
  },
  {
    varCodigo: '27',
    cargoPublico: 'Almirantes de la Marina de Guerra del Perú',
  },
  {
    varCodigo: '28',
    cargoPublico: 'Vicealmirantes de la Marina de Guerra del Perú',
  },
  {
    varCodigo: '29',
    cargoPublico: 'Contralmirantes de la Marina de Guerra del Perú',
  },
  {
    varCodigo: '30',
    cargoPublico: 'Capitanes de Navío de la Marina de Guerra del Perú',
  },
  {
    varCodigo: '31',
    cargoPublico: 'Agregados Navales',
  },
  {
    varCodigo: '32',
    cargoPublico: 'Comandante General de la Fuerza Aérea del Perú',
  },
  {
    varCodigo: '33',
    cargoPublico: 'Jefe del Estado Mayor General de la Fuerza Aérea',
  },
  {
    varCodigo: '34',
    cargoPublico: 'Inspector General de la Fuerza Aérea del Perú',
  },
  {
    varCodigo: '35',
    cargoPublico: 'General del Aire de la Fuerza Aérea del Perú',
  },
  {
    varCodigo: '36',
    cargoPublico: 'Teniente General de la Fuerza Aérea del Perú',
  },
  {
    varCodigo: '37',
    cargoPublico: 'Mayor General de la Fuerza Aérea del Perú',
  },
  {
    varCodigo: '38',
    cargoPublico: 'Coroneles de la Fuerza Aérea del Perú',
  },
  {
    varCodigo: '39',
    cargoPublico: 'Comandantes de la Fuerza Aérea del Perú',
  },
  {
    varCodigo: '40',
    cargoPublico: 'Director General de Política de Ingresos Públicos',
  },
  {
    varCodigo: '41',
    cargoPublico: 'Director General de Asuntos Económicos y Sociales',
  },
  {
    varCodigo: '42',
    cargoPublico: 'Director Nacional de Presupuesto Público',
  },
  {
    varCodigo: '43',
    cargoPublico: 'Director Nacional del Tesoro Público',
  },
  {
    varCodigo: '44',
    cargoPublico: 'Director Nacional de Endeudamiento Público',
  },
  {
    varCodigo: '45',
    cargoPublico: 'Director Nacional de Contabilidad Pública',
  },
  {
    varCodigo: '46',
    cargoPublico: 'Director Nacional de Asuntos de Economía Internac.',
  },
  {
    varCodigo: '47',
    cargoPublico: 'Director General de la Of. de Plan. y Presupuesto',
  },
  {
    varCodigo: '48',
    cargoPublico: 'Director General de Gobierno Interior',
  },
  {
    varCodigo: '49',
    cargoPublico: 'Director de la Oficina General de Inteligencia',
  },
  {
    varCodigo: '50',
    cargoPublico: 'Director General de la Policía Nacional del Perú',
  },
  {
    varCodigo: '51',
    cargoPublico: 'Director General de Migraciones y Naturalización',
  },
  {
    varCodigo: '52',
    cargoPublico: 'Director General de Control de Servicios de Segur.',
  },
  {
    varCodigo: '53',
    cargoPublico: 'Jefe de la Oficina Ejecutiva de Control de Drogas',
  },
  {
    varCodigo: '54',
    cargoPublico: 'General de la PNP',
  },
  {
    varCodigo: '55',
    cargoPublico: 'Coronel de la PNP',
  },
  {
    varCodigo: '56',
    cargoPublico: 'Comandante de la PNP',
  },
  {
    varCodigo: '57',
    cargoPublico: 'Jefe de Gabinete de Asesoría Técnica',
  },
  {
    varCodigo: '58',
    cargoPublico: 'Director Nacional de Justicia',
  },
  {
    varCodigo: '59',
    cargoPublico: 'Director Nacional de Asuntos Jurídicos',
  },
  {
    varCodigo: '60',
    cargoPublico: 'Jefe de la Subsecretaría de Asuntos Económicos',
  },
  {
    varCodigo: '61',
    cargoPublico: 'Jefe de la Subsecretaría de Administración',
  },
  {
    varCodigo: '62',
    cargoPublico: 'Titular de OPD',
  },
  {
    varCodigo: '63',
    cargoPublico: 'Directores',
  },
  {
    varCodigo: '64',
    cargoPublico: 'Presidente',
  },
  {
    varCodigo: '65',
    cargoPublico: 'Miembro del Directorio',
  },
  {
    varCodigo: '66',
    cargoPublico: 'Presidente Ejecutivo',
  },
  {
    varCodigo: '67',
    cargoPublico: 'Gerente General',
  },
  {
    varCodigo: '68',
    cargoPublico: 'Gerente de Asesoría Legal',
  },
  {
    varCodigo: '69',
    cargoPublico: 'Gerente de Administración e Informática',
  },
  {
    varCodigo: '70',
    cargoPublico: 'Director Ejecutivo',
  },
  {
    varCodigo: '71',
    cargoPublico: 'Director de Contrainteligencia',
  },
  {
    varCodigo: '72',
    cargoPublico: 'Director de Inteligencia Estratégica',
  },
  {
    varCodigo: '73',
    cargoPublico: 'Director de Informaciones',
  },
  {
    varCodigo: '74',
    cargoPublico: 'Gerente Legal',
  },
  {
    varCodigo: '75',
    cargoPublico: 'Gerente de Administración y Finanzas',
  },
  {
    varCodigo: '76',
    cargoPublico: 'Jefe',
  },
  {
    varCodigo: '77',
    cargoPublico: 'Intendente',
  },
  {
    varCodigo: '78',
    cargoPublico: 'Superintendente Nac. de Administración Tributaria',
  },
  {
    varCodigo: '79',
    cargoPublico: 'Superintendente Nac. Adjunto de Aduanas',
  },
  {
    varCodigo: '80',
    cargoPublico: 'Superintendente Nac. Adjunto de Tributos Internos',
  },
  {
    varCodigo: '81',
    cargoPublico: 'Intendente Nac. de la Intendencia Naciona Jurídica',
  },
  {
    varCodigo: '82',
    cargoPublico: 'Intendente Nacional de Administración',
  },
  {
    varCodigo: '83',
    cargoPublico: 'Intendente de Fiscaliz. y Gestión de Recaudación',
  },
  {
    varCodigo: '84',
    cargoPublico: 'Miembros del Consejo Nacional Penitenciario',
  },
  {
    varCodigo: '85',
    cargoPublico: 'Superintendente Nacional de los Registros Públicos',
  },
  {
    varCodigo: '86',
    cargoPublico: 'Superintendente Adjunto',
  },
  {
    varCodigo: '87',
    cargoPublico: 'Gerente de Presupuesto y Desarrollo',
  },
  {
    varCodigo: '88',
    cargoPublico: 'Superintendente',
  },
  {
    varCodigo: '89',
    cargoPublico: 'Jefe del INDECI',
  },
  {
    varCodigo: '90',
    cargoPublico: 'Directores Regionales del INDECI',
  },
  {
    varCodigo: '91',
    cargoPublico: 'Gerencia de Proyectos en Costa',
  },
  {
    varCodigo: '92',
    cargoPublico: 'Gerencia de Proyectos en Sierra y Selva',
  },
  {
    varCodigo: '93',
    cargoPublico: 'Jefe de INIA',
  },
  {
    varCodigo: '94',
    cargoPublico: 'Director General de Investigación Agraria',
  },
  {
    varCodigo: '95',
    cargoPublico: 'Director General de Extensión Agraria',
  },
  {
    varCodigo: '96',
    cargoPublico: 'Jefe Nacional de SENASA',
  },
  {
    varCodigo: '97',
    cargoPublico: 'Director Ejecutivo SENASA Lima-Callao',
  },
  {
    varCodigo: '98',
    cargoPublico: 'Secretaría Técnica',
  },
  {
    varCodigo: '99',
    cargoPublico: 'Asesores de la Alta Dirección',
  },
  {
    varCodigo: '100',
    cargoPublico: 'Jefe de Asesoría Legal',
  },
  {
    varCodigo: '101',
    cargoPublico: 'Jefe de la Unidad Admiistrativa',
  },
  {
    varCodigo: '102',
    cargoPublico: 'Jefe de la Unidad de Planeamiento y Resultados',
  },
  {
    varCodigo: '103',
    cargoPublico: 'Jedel del INS',
  },
  {
    varCodigo: '104',
    cargoPublico: 'Sub-Jefe del INS',
  },
  {
    varCodigo: '105',
    cargoPublico: 'Jefe del SIS',
  },
  {
    varCodigo: '106',
    cargoPublico: 'Gerente de Operaciones',
  },
  {
    varCodigo: '107',
    cargoPublico: 'Gerente de Recuperaciones',
  },
  {
    varCodigo: '108',
    cargoPublico: 'Gerencia de Fiscalización',
  },
  {
    varCodigo: '109',
    cargoPublico: 'Gerente de Supervisión',
  },
  {
    varCodigo: '110',
    cargoPublico: 'Embajadores Peruanos permanentes en el Perú',
  },
  {
    varCodigo: '111',
    cargoPublico: 'Embajadores Peruanos en el Extranjero',
  },
  {
    varCodigo: '112',
    cargoPublico: 'Cónsules Peruanos en el Extranjero',
  },
  {
    varCodigo: '113',
    cargoPublico: 'Congresista de la República',
  },
  {
    varCodigo: '114',
    cargoPublico: 'Asesores de Congresistas',
  },
  {
    varCodigo: '115',
    cargoPublico: 'Asesores de Comisiones',
  },
  {
    varCodigo: '116',
    cargoPublico: 'Oficial Mayor del Congreso Nac. de la República',
  },
  {
    varCodigo: '117',
    cargoPublico: 'Presidente Corte Suprema',
  },
  {
    varCodigo: '118',
    cargoPublico: 'Vocales Supremos',
  },
  {
    varCodigo: '119',
    cargoPublico: 'Presidente de la Corte Superior',
  },
  {
    varCodigo: '120',
    cargoPublico: 'Vocales Superiores',
  },
  {
    varCodigo: '121',
    cargoPublico: 'Miembros del Pleno',
  },
  {
    varCodigo: '122',
    cargoPublico: 'Jefe Nacional',
  },
  {
    varCodigo: '123',
    cargoPublico: 'Sub-Jefe Nacional',
  },
  {
    varCodigo: '124',
    cargoPublico: 'Fiscal de la Nación',
  },
  {
    varCodigo: '125',
    cargoPublico: 'Fiscales Supremos',
  },
  {
    varCodigo: '126',
    cargoPublico: 'Fiscales Superiores',
  },
  {
    varCodigo: '127',
    cargoPublico: 'Fiscales Provinciales',
  },
  {
    varCodigo: '128',
    cargoPublico: 'Contralor General de la República',
  },
  {
    varCodigo: '129',
    cargoPublico: 'Vicecontralor General de la República',
  },
  {
    varCodigo: '130',
    cargoPublico: 'Superintendente de Banca Seguros y AFPs',
  },
  {
    varCodigo: '131',
    cargoPublico: 'Superintendente Adjunto de Banca y Microfinanzas',
  },
  {
    varCodigo: '132',
    cargoPublico: 'Superintendente Adjunto de Seguros',
  },
  {
    varCodigo: '133',
    cargoPublico: 'Superintendente Adjunto de Admin. Priv. de Fondos',
  },
  {
    varCodigo: '134',
    cargoPublico: 'Superintendente Adjunto de Riesgos',
  },
  {
    varCodigo: '135',
    cargoPublico: 'Superintendente Adjunto de la UIF',
  },
  {
    varCodigo: '136',
    cargoPublico: 'Gerencia de Contabilidad y Supervisión',
  },
  {
    varCodigo: '137',
    cargoPublico: 'Gerencia de Estabilidad Financiera',
  },
  {
    varCodigo: '138',
    cargoPublico: 'Director de la Oficialia de Cumplimiento',
  },
  {
    varCodigo: '139',
    cargoPublico: 'Presidente Regional',
  },
  {
    varCodigo: '140',
    cargoPublico: 'Vicepresidente Regional',
  },
  {
    varCodigo: '141',
    cargoPublico: 'Miembros del Consejo Regional',
  },
  {
    varCodigo: '142',
    cargoPublico: 'Alcalde',
  },
  {
    varCodigo: '143',
    cargoPublico: 'Regidor Provincial',
  },
  {
    varCodigo: '144',
    cargoPublico: 'Regidor Distrital',
  },
  {
    varCodigo: '145',
    cargoPublico: 'Miembro de la Junta General de Accionistas',
  },
  {
    varCodigo: '146',
    cargoPublico: 'Candidato a Elecciones',
  },
  {
    varCodigo: '147',
    cargoPublico: 'Candidato al Congreso',
  },
]
