export default [
  {
    compuestoID: '000001',
    ocupacion: 'Ama de Casa',
  },
  {
    compuestoID: '000002',
    ocupacion: 'Desempleado (a)',
  },
  {
    compuestoID: '000003',
    ocupacion: 'Empleado',
  },
  {
    compuestoID: '000004',
    ocupacion: 'Empleador (a)',
  },
  {
    compuestoID: '000005',
    ocupacion: 'Estudiante',
  },
  {
    compuestoID: '000006',
    ocupacion: 'Jubilado (a)',
  },
  {
    compuestoID: '000007',
    ocupacion: 'Miembro de las Fuerzas armadas /Miembro del clero',
  },
  {
    compuestoID: '000008',
    ocupacion: 'Obrero (a)',
  },
  {
    compuestoID: '000009',
    ocupacion: 'Trabajador (a) del hogar',
  },
  {
    compuestoID: '000010',
    ocupacion: 'Trabajador (a) independiente',
  },
  {
    compuestoID: '000099',
    ocupacion: 'No declara',
  },
]
